<template>
    <div class="container">
      <!-- <calendar-sidebar
        :events="events"
        :weekends-visible="weekendsVisible"
        @set-weekends-visible="setweekendsVisible"
      /> -->
      <div class="calendar">
          <full-calendar class="full-calendar" :options="config">
              <template #eventContent="{ timeText, event }">
              <b>{{ timeText }}</b>
              <i>{{ event.title }}</i>
              </template>
          </full-calendar>
      </div>
      <v-dialog v-model="showEvent" max-width="700" height="500">
        <event-modal :event="event" />
      </v-dialog>
    </div>
</template>

<script>
import EventModal from "../components/EventModal.vue";
import { mapGetters, mapActions } from "vuex";

// import CalendarSidebar from "@/components/CalendarSidebar";

import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
// import basicDay from "@fullcalendar/daygrid";
// import basicWeek from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
// import deLocale from '@fullcalendar/core/locales/de';

// import CalendarSidebar from "@/components/CalendarSidebar.vue";

// import store from '@/store/index'

// import { format } from 'date-fns'
// import axios from 'axios'

export default {
  components: {
    FullCalendar,
    EventModal,
    // CalendarSidebar,
  },
  computed: {
    ...mapGetters(["events", "weekendsVisible", "holidays"]),

    config() {
      return {
        ...this.configOptions,
        ...this.eventHandlers,
      };
    },

    configOptions() {
      return {
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        events: this.events,
        weekends: this.weekendsVisible,
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,dayGridWeek,dayGridDay"
        },
        initialView: "dayGridMonth",
        firstDay: 1,
        locale: 'de',
        buttonText: {
            today: 'heute',
            month: 'Monat',
            week: 'Woche',
            day: 'Tag'
        }
      };
    },

    eventHandlers() {
      return {
        dateClick: this.onDateClick,
        eventClick: this.onEventClick,
        eventDrop: this.onEventDrop,
        eventResize: this.onEventDrop,
        // select: this.onDateSelect,
      };
    },
  },
  methods: {
    ...mapActions([
      "createEvent",
      "updateEvent",
      "deleteEvent",
      "setweekendsVisible",
      "retrieveHolidays"
    ]),

    onDateClick(payload) {
      console.log("onDateClick: start");
      console.log(payload)
      const title = prompt("Please enter a new title for your event");
      console.log("title:", title);

      if (!title) {
        console.log("no title");
        return;
      }

      const id = (this.events.length + 1) * 10;
      const { start, end, date, allDay } = payload;

      console.log("id:", id);
      console.log("onDateClick: end");

      return this.createEvent({
        id,
        title,
        date,
        start,
        end,
        allDay,
      });
    },

    onDateSelect(payload) {
      console.log("onDateSelect: start");
      console.log(payload)
      return this.onDateClick(payload);
    },

    onEventClick({ event }) {
      console.log(event)
      this.event = event
      this.showEvent = true
      // const confirmed = confirm(
      //   `Are you sure you want to delete the event '${event.title}'?`
      // );

      // if (!confirmed) {
      //   return;
      // }

      // return this.deleteEvent(event.id);
    },

    onEventDrop({ event }) {
      console.log("onEventDrop: start");
      console.log(event)
      return this.updateEvent(event);
    },
  },
  data () {
    return {
      info: null,
      loading: true,
      errored: false,
      showEvent: false,
      event: {}
    }
  },
  // mounted() {
  //   const date = new Date()
  //   const formattedDate = format(date, 'yyyy-MM-dd')
  //   console.log(date)
  //   console.log(formattedDate)
  //   console.log(store.state)
  //   // this.$store.dispatch('retrieveCalendar')
  //   // this.$store.dispatch('retrieveHolidays')
  // }
  // mounted () {
  //   axios
  //     .get('localhost:8000')
  //     .then(response => {
  //       this.info = response.data.bpi
  //     })
  //     .catch(error => {
  //       console.log(error)
  //       this.errored = true
  //     })
  //     .finally(() => this.loading = false)
  // }
};
</script>

<style>
.calendar {
  height: 80vh;
}
</style>