<template>
    <div>
        <v-card>
            <v-card-title>
                <h2>{{event.title}}</h2>
            </v-card-title>
            {{ event.extendedProps }}
            {{ event }}
        </v-card>
        <!-- <fieldset>
            <legend>Event details</legend>
            {{ event }}
        </fieldset> -->
    </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters(["events"])
    },
    props: {
        event: {
            type: Object,
            required: true
        }
    }
}
</script>